<template>
  <div class="home" 
    v-touch:start="holdStart" 
    v-touch:end="holdEnd"  
    :class="{'hold': hold}">

    <div class="main">
      <div class="logo">
        <div class="ascii" @click="newAsciiLogo">
          <pre> {{ logo }} </pre>
          <!-- <div class="onscreen-text">.co</div> -->
        </div>
        <div class="logo-mark" @click="newAsciiLogo"> <img src="WolfGhost_Logo_01.gif" alt=""></div>
        <div class="subtitle" @click="newAsciiLogo"><span class="drop-cap">a <br />c</span><span class="">0llaboration</span></div>

        </div>
        <div class="hidden">
          <div>
            {{ subtitle }}
          </div>
          <div class="binary">
            {{ binary }}
          </div>
      </div>

    </div>
    <div class="emoji">
      <div>
        <a href="https://www.reeceparker.com/" target="_blank" >🐺</a> <a href="https://www.battleaxe.co/" target="_blank" >👻</a>
      </div>
    </div>
  </div>
</template>

<script>
// import func from 'vue-editor-bridge'
import Vue from "vue";
import Vue2TouchEvents from "vue2-touch-events";

Vue.use(Vue2TouchEvents);

export default {
  data: () => ({
    hold: false,
    logo: '',
    logoIdx: 0,
    asciiLogos: [
//       `
//  ________         __   ___ _______ __                 __   
// |  |  |  |.-----.|  |.'  _|     __|  |--.-----.-----.|  |_ 
// |  |  |  ||  _  ||  ||   _|    |  |     |  _  |__ --||   _|
// |________||_____||__||__| |_______|__|__|_____|_____||____|
// `,
// `
//          ***** *    **   ***               ***        ***         * ***        *                                       
//   ******  *  *****    ***               ***     ** ***      *  ****  *   **                                  *     
//  **   *  *     *****   ***               **    **   ***    *  *  ****    **                                 **     
// *    *  **     * **      **              **    **         *  **   **     **                                 **     
//     *  ***     *         **    ****      **    **        *  ***          **           ****       ****     ******** 
//    **   **     *         **   * ***  *   **    ******   **   **          **  ***     * ***  *   * **** * ********  
//    **   **     *         **  *   ****    **    *****    **   **   ***    ** * ***   *   ****   **  ****     **     
//    **   **     *         ** **    **     **    **       **   **  ****  * ***   *** **    **   ****          **     
//    **   **     *         ** **    **     **    **       **   ** *  ****  **     ** **    **     ***         **     
//    **   **     *         ** **    **     **    **       **   ***    **   **     ** **    **       ***       **     
//     **  **     *         ** **    **     **    **        **  **     *    **     ** **    **         ***     **     
//      ** *      *         *  **    **     **    **         ** *      *    **     ** **    **    ****  **     **     
//       ***      ***      *    ******      **    **          ***     *     **     **  ******    * **** *      **     
//        ******** ********      ****       *** * **           *******      **     **   ****        ****        **    
//          ****     ****                    ***   **            ***         **    **                                 
//                                                                                 *                                  
//                                                                                *                                   
//                                                                               *                                    
//                                                                              *           
// `,
`
                                                ,                                                            
                          :                     Et                                   :                       
                         t#,                    E#t                                 t#,           .          
                        ;##W.             i     E##t             .Gt .    .        ;##W.         ;W          
            ;          :#L:WE            LE     E#W#t           j#W: Di   Dt      :#L:WE        f#E GEEEEEEEL
          .DL         .KG  ,#D          L#E     E#tfL.        ;K#f   E#i  E#i    .KG  ,#D     .E#f  ,;;L#K;;.
  f.     :K#L     LWL EE    ;#f        G#W.     E#t         .G#D.    E#t  E#t    EE    ;#f   iWW;      t#E   
  EW:   ;W##L   .E#f f#.     t#i      D#K.   ,ffW#Dffj.    j#K;      E#t  E#t   f#.     t#i L##Lffi    t#E   
  E#t  t#KE#L  ,W#;  :#G     GK      E#K.     ;LW#ELLLf. ,K#f   ,GD; E########f.:#G     GK tLLG##L     t#E   
  E#t f#D.L#L t#K:    ;#L   LW.    .E#E.        E#t       j#Wi   E#t E#j..K#j... ;#L   LW.   ,W#i      t#E   
  E#jG#f  L#LL#G       t#f f#:    .K#E          E#t        .G#D: E#t E#t  E#t     t#f f#:   j#E.       t#E   
  E###;   L###j         f#D#;    .K#D           E#t          ,K#fK#t E#t  E#t      f#D#;  .D#j         t#E   
  E#K:    L#W;           G#t    .W#G            E#t            j###t f#t  f#t       G#t  ,WK,          t#E   
  EG      LE.             t    :W##########Wt   E#t             .G#t  ii   ii        t   EG.            fE   
  ;       ;@                   :,,,,,,,,,,,,,.  ;#t               ;;                     ,               :   
                                                 :;                                                          
`,
`
 _|          _|            _|      _|_|    _|_|_|  _|                              _|      
 _|          _|    _|_|    _|    _|      _|        _|_|_|      _|_|      _|_|_|  _|_|_|_|  
 _|    _|    _|  _|    _|  _|  _|_|_|_|  _|  _|_|  _|    _|  _|    _|  _|_|        _|      
   _|  _|  _|    _|    _|  _|    _|      _|    _|  _|    _|  _|    _|      _|_|    _|      
     _|  _|        _|_|    _|    _|        _|_|_|  _|    _|    _|_|    _|_|_|        _|_|
`,
// `
// '##:::::'##::'#######::'##:::::::'########::'######:::'##::::'##::'#######:::'######::'########:
//  ##:'##: ##:'##.... ##: ##::::::: ##.....::'##... ##:: ##:::: ##:'##.... ##:'##... ##:... ##..::
//  ##: ##: ##: ##:::: ##: ##::::::: ##::::::: ##:::..::: ##:::: ##: ##:::: ##: ##:::..::::: ##::::
//  ##: ##: ##: ##:::: ##: ##::::::: ######::: ##::'####: #########: ##:::: ##:. ######::::: ##::::
//  ##: ##: ##: ##:::: ##: ##::::::: ##...:::: ##::: ##:: ##.... ##: ##:::: ##::..... ##:::: ##::::
//  ##: ##: ##: ##:::: ##: ##::::::: ##::::::: ##::: ##:: ##:::: ##: ##:::: ##:'##::: ##:::: ##::::
// . ###. ###::. #######:: ########: ##:::::::. ######::: ##:::: ##:. #######::. ######::::: ##::::
// :...::...::::.......:::........::..:::::::::......::::..:::::..:::.......::::......::::::..:::::
// `,
`
db   d8b   db  .d88b.  db      d88888b  d888b  db   db  .d88b.  .d8888. d888888b 
88   I8I   88 .8P  Y8. 88      88'     88' Y8b 88   88 .8P  Y8. 88'  YP '~~88~~' 
88   I8I   88 88    88 88      88ooo   88      88ooo88 88    88 '8bo.      88    
Y8   I8I   88 88    88 88      88~~~   88  ooo 88~~~88 88    88   'Y8b.    88    
'8b d8'8b d8' '8b  d8' 88booo. 88      88. ~8~ 88   88 '8b  d8' db   8D    88    
 '8b8' '8d8'   'Y88P'  Y88888P YP       Y888P  YP   YP  'Y88P'  '8888Y'    YP    
`,
`
'7MMF'     A     '7MF'     '7MM    .d' "".g8"""bgd '7MM                           mm    
  'MA     ,MA     ,V         MM    dM' .dP'     'M   MM                           MM    
   VM:   ,VVM:   ,V ,pW"Wq.  MM   mMMmmdM'       '   MMpMMMb.  ,pW"Wq.  ,pP"Ybd mmMMmm  
    MM.  M' MM.  M'6W'   'Wb MM    MM  MM            MM    MM 6W'   'Wb 8I   '"   MM    
    'MM A'  'MM A' 8M     M8 MM    MM  MM.    '7MMF' MM    MM 8M     M8 'YMMMa.   MM    
     :MM;    :MM;  YA.   ,A9 MM    MM  'Mb.     MM   MM    MM YA.   ,A9 L.   I8   MM    
      VF      VF    'Ybmd9'.JMML..JMML.  '"bmmmdPY .JMML  JMML.'Ybmd9'  M9mmmP'   'Mbmo
`,
`                                                                                            
@@@  @@@  @@@   @@@@@@   @@@       @@@@@@@@   @@@@@@@@  @@@  @@@   @@@@@@    @@@@@@   @@@@@@@  
@@@  @@@  @@@  @@@@@@@@  @@@       @@@@@@@@  @@@@@@@@@  @@@  @@@  @@@@@@@@  @@@@@@@   @@@@@@@  
@@!  @@!  @@!  @@!  @@@  @@!       @@!       !@@        @@!  @@@  @@!  @@@  !@@         @@!    
!@!  !@!  !@!  !@!  @!@  !@!       !@!       !@!        !@!  @!@  !@!  @!@  !@!         !@!    
@!!  !!@  @!@  @!@  !@!  @!!       @!!!:!    !@! @!@!@  @!@!@!@!  @!@  !@!  !!@@!!      @!!    
!@!  !!!  !@!  !@!  !!!  !!!       !!!!!:    !!! !!@!!  !!!@!!!!  !@!  !!!   !!@!!!     !!!    
!!:  !!:  !!:  !!:  !!!  !!:       !!:       :!!   !!:  !!:  !!!  !!:  !!!       !:!    !!:    
:!:  :!:  :!:  :!:  !:!   :!:      :!:       :!:   !::  :!:  !:!  :!:  !:!      !:!     :!:    
 :::: :: :::   ::::: ::   :: ::::   ::        ::: ::::  ::   :::  ::::: ::  :::: ::      ::    
  :: :  : :     : :  :   : :: : :   :         :: :: :    :   : :   : :  :   :: : :       :     
`,
`
#     #                       #####                             
#  #  #  ####  #      ###### #     # #    #  ####   ####  ##### 
#  #  # #    # #      #      #       #    # #    # #        #   
#  #  # #    # #      #####  #  #### ###### #    #  ####    #   
#  #  # #    # #      #      #     # #    # #    #      #   #   
#  #  # #    # #      #      #     # #    # #    # #    #   #   
 ## ##   ####  ###### #       #####  #    #  ####   ####    #   
`
    ],
    subtitle: 'We are formless beings of light, drifting through the cosmos, manipulating electrons, believing the possibility of empathically projecting an experience and emotions.',
    binary: '01010010 01100101 01100101 01100011 01100101 00100000 01100001 01101110 01100100 00100000 01000001 01100100 01100001 01101101 00100000 01100001 01110010 01100101 00100000 01100010 01110101 01101001 01101100 01100100 01101001 01101110 01100111 00100000 01110011 01101111 01101101 01100101 01110100 01101000 01101001 01101110 01100111 00100000 01110000 01101100 01100001 01111001 01100110 01110101 01101100 01101100 01111001 00100000 01101001 01101110 01110100 01100101 01110010 01100001 01100011 01110100 01101001 01110110 01100101'
  }),
  methods: {
    holdStart() {
      this.hold = true
    },
    holdEnd() {
      this.hold = false
    },
    newAsciiLogo (type) {
      if (type == 'random') {
        this.logoIdx = Math.floor(Math.random() * this.asciiLogos.length )
      } else {
        this.logoIdx = (this.logoIdx + 1) % (this.asciiLogos.length - 1)
      }
      this.logo = this.asciiLogos[this.logoIdx]

      this.glitchSeq(this.logo)
    },
    glitchSeq(text) {
      const times = [getRandomIntInclusive(30, 100)]
      setTimeout(() => {
        this.logo = this.glitchText(text)
        setTimeout(() => { this.logo = this.glitchText(this.logo) }, times[0]);
        setTimeout(() => { this.logo = this.glitchText(this.logo) }, 100);
        setTimeout(() => { this.logo = this.glitchText(this.logo) }, times[2]);
        setTimeout(() => { this.logo = this.asciiLogos[this.logoIdx] }, 100);


        setTimeout(() => { this.glitchSeq(text)  }, 2000);
      }, 2000);

      function getRandomIntInclusive(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1) + min); //The maximum is inclusive and the minimum is inclusive
      }
    },
    glitchText(str) {
      let glitchStr = ''
      const ctu = [ '%', '-', '/', '!', '*', '', '  ', ]
      const s = str.split('')

      const probability = 0.05

      for (let i = 0; i < s.length; i++) {
        let char = str[i];
        let changeChar = (probability > Math.random())
        if (char != ' ' && char != '\n' && changeChar) {
          char = ctu[Math.floor(Math.random() * ctu.length)];
        }
          glitchStr += char
        }
      
      return glitchStr
      
    },
  },
  mounted() {
    this.newAsciiLogo('random')
  },
  filter: {

  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.home {
  /* height: 1000vh; */
}
.main {
  /* border: 1px solid green; */
  height: 84vh;
  width: 84%;
  margin: 2% 8% 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  position: fixed;
}
.main>* {
  flex-grow: 1;
  justify-content: center;
}
.logo {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.ascii {
  height: min(200px, 20vh);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80vw;
  max-width: 450px;
  /* border: 1px solid salmon; */
  /* font-size: 10em; */
}
.ascii pre {
  /* font-size: 36%; */
  font-size: 7px;
  /* font-size: min(60%, 7px); */

}
.onscreen-text, .subtitle {
  font-style: italic;
  font-size: min(5vw, 20px);
  text-transform: uppercase;
}
.subtitle {
  margin-top: 20px;
  text-align: center;
  line-height: 2em;
  letter-spacing: 0.2em;
}
.underline {
  text-decoration: underline;
  text-decoration-thickness: 1%;
  text-underline-offset: 3px;

}
.drop-cap {
  font-size: min(6vw, 26px);
  font-weight: 200;
  vertical-align: top;
}
.header {
  width: 100%;
}
.logo-mark {
  padding: 12px;
  /* background: var(--text-color); */
  width: min(30vw, 24vh);
  max-width: 250px;
  margin: min(32px, 2vh);
  /* border-radius: 40px; */
}
.logo-mark>* {
  width: 100%;
}
.hidden {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--bg-color);
  text-align: center;
  width: 80vw;
  max-width: 570px;
}
.hidden>* {
  margin-bottom: 16px;
}
.binary {
  font-size: 8px;
}
.hold {
  background: #DC515E;
  transition: background 10s;
}
.emoji {
  width: calc(100% + 16px);
  height: 100vh;
  text-align: center;
  display: block;
  overflow-y: scroll;
}
.emoji>* {
  width: 100%;
  padding-top: 130vh;
  padding-bottom: 20px;
}
.emoji a {
  z-index: 10;
  text-decoration: none;
}
@media screen and (max-height: 400px) {
  .ascii pre {
    font-size: 4px;
    height: 80px;
  }
  .logo-mark {
    margin: 4% auto 0;
  }
}
@media screen and (max-width: 500px) {
  .ascii {
    height: 110px;
  }
  .ascii pre {
    font-size: 1vw;
  }
  .hidden {
    font-size: 8px;
  }
  .logo-mark {
    margin: 4%;
  }
}
</style>
